import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FundCommentsSection from './FundCommentsSection';
import { FundDetailDialog } from './FundDetailDialog';
import { FundDetailFooter } from './FundDetailFooter';
import { FundsDetailHeader } from './FundDetailHeader';
import { FundDetailSection } from './FundDetailSection';
import FundStatusFlow from './FundDetailStatusFlow';
import { FundKpis } from './FundKpis';
import { SelectedFundState } from './Funds';
import { useGetFundByIdQuery } from './useGetFundByIdQuery';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { namespaces } from '@shared/constants';

const canViewFundDetailPermissions = [permissions.coInnovationFunds.fundsManagement.view];
type DialogTypes = 'cancel' | 'approve' | 'reject' | 'changeRequest' | 'approveCR' | 'rejectCR' | 'cancelCR';

export const FundDetail = () => {
    const { fundId } = useParams<{ fundId?: string }>();
    const { data } = useGetFundByIdQuery(fundId ?? '');
    const [selectedFundState, setSelectedFundState] = useState<SelectedFundState>();
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const [initialPage, setInitialPage] = useState();
    const sections = [
        {
            title: t('coInnovationFunds.fundsManagement.partner'),
            value: data?.partner?.name ?? 'N/A',
            titleTestId: 'partner-title',
            valueTestId: 'partner-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.providedFunds'),
            value: `${data?.provided ?? 'N/A'} CHF`,
            titleTestId: 'providedFunds-title',
            valueTestId: 'providedFunds-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.carryForward'),
            value: `${data?.carryForward ?? 'N/A'} CHF`,
            titleTestId: 'carryForward-title',
            valueTestId: 'carryForward-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.expired'),
            value: `${data?.expired ?? 'N/A'} CHF`,
            titleTestId: 'expired-title',
            valueTestId: 'expired-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.beginDate'),
            value: data?.beginDate ?? 'N/A',
            titleTestId: 'beginDate-title',
            valueTestId: 'beginDate-value',
        },
        {
            title: t('coInnovationFunds.fundsManagement.consumptionExpirationDate'),
            value: data?.consumptionExpirationDate ?? 'N/A',
            titleTestId: 'consumptionDeadline-title',
            valueTestId: 'consumptionDeadline-value',
        },
    ];

    const handleActionClick = (actionType: DialogTypes) => {
        setSelectedFundState(previousState => {
            const id = previousState?.id || data?.id || '';
            const types = previousState?.types.includes(actionType)
                ? [...previousState.types]
                : [...(previousState?.types || []), actionType];

            return { id, types };
        });
    };

    useEffect(() => {
        const savedPagination = sessionStorage.getItem('fundTablePagination');
        if (savedPagination) {
            const paginationObject = JSON.parse(savedPagination);
            const pageNumber = paginationObject.page;
            setInitialPage(pageNumber);
        }
    }, []);

    return (
        <HasPermission neededPermissions={canViewFundDetailPermissions}>
            <div className="mb-20  flex w-full flex-col" data-testid="fund-detail">
                <FundsDetailHeader
                    navigateBackPath={`/co-innovation-funds/funds-management?page=${initialPage}`}
                    submissionDate={data?.submissionDate ?? ''}
                />
                <div className="p-4">
                    <FundKpis sections={sections} />
                </div>
                <div className="flex flex-grow flex-col gap-2 overflow-hidden md:flex-row md:gap-4">
                    {data && (
                        <FundDetailSection title={t('coInnovationFunds.fundsManagement.statusFlow')}>
                            <FundStatusFlow data={data} />
                        </FundDetailSection>
                    )}
                    {data && (
                        <FundDetailSection title={t('coInnovationFunds.fundsManagement.comments')}>
                            <div className=" max-h-fit overflow-auto">
                                <FundCommentsSection data={data} />
                            </div>
                        </FundDetailSection>
                    )}
                </div>
                <>
                    <FundDetailFooter statusId={data?.statusId} handleActionClick={handleActionClick} />
                    <FundDetailDialog
                        selectedFundState={selectedFundState}
                        setSelectedFundState={setSelectedFundState}
                        data={data}
                    />
                </>
            </div>
        </HasPermission>
    );
};
