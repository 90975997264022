import { useTranslation } from 'react-i18next';

import { useGetPartnerPrioritiesQuery } from './useGetPartnerPrioritiesQuery';

import { namespaces } from '@shared/constants';

type Props = {
    partnerId: string;
};

export const PartnerDetails = ({ partnerId }: Props) => {
    const data = useGetPartnerPrioritiesQuery(partnerId);
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    // Function to render lists with sub-items
    const renderListWithSubItems = (items: string[]) => {
        return (
            <ul className="ml-6 list-disc">
                {items.map((item, index) => {
                    if (item?.startsWith('- ')) {
                        // Collect sub-items
                        const subItems = [];
                        let nextIndex = index + 1;

                        while (
                            nextIndex < items.length &&
                            items[nextIndex] !== undefined &&
                            items[nextIndex]?.startsWith('--')
                        ) {
                            subItems.push(items[nextIndex]?.replace('--', ''));
                            nextIndex++;
                        }

                        // Render the main item and its sub-items, if any
                        return (
                            <li key={index}>
                                {item.replace('- ', '')}
                                {subItems.length > 0 && (
                                    <ul className="ml-6 list-[circle]">
                                        {subItems.map((subItem, subIndex) => (
                                            <li key={`${index}-${subIndex}`}>{subItem}</li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        );
                    }

                    // Skip sub-items as they will be handled with their parent
                    return null;
                })}
            </ul>
        );
    };

    return (
        <article className="flex max-w-4xl flex-col">
            <h1 className="mb-4 text-2xl font-bold">{t('coInnovationFunds.priorities.details.sponsorsSubtitle')}</h1>
            {data ? (
                <div className="flex flex-col">
                    {data.sponsors.map(x => (
                        <span key={x.name}>
                            <span className="font-bold">{x.title}</span> : {x.name}
                        </span>
                    ))}
                </div>
            ) : null}

            <h1 className="my-4 text-2xl font-bold">{t('coInnovationFunds.priorities.details.contractSubtitle')}</h1>
            {data && data.contractDetails.length > 0 && (
                <ul className="ml-6 list-disc">
                    {data.contractDetails.map((detail, index) => (
                        <li key={index}>{detail}</li>
                    ))}
                </ul>
            )}

            {data?.criteria && data.criteria.length > 0 && (
                <>
                    <h1 className="my-4 text-2xl font-bold">
                        {t('coInnovationFunds.priorities.details.criteriaSubtitle')}
                    </h1>
                    {renderListWithSubItems(data.criteria)}
                </>
            )}

            {data?.governance && data.governance.length > 0 && (
                <>
                    <h1 className="my-4 text-2xl font-bold">
                        {t('coInnovationFunds.priorities.details.governanceSubtitle')}
                    </h1>
                    {renderListWithSubItems(data.governance)}
                </>
            )}

            <h1 className="my-4 text-2xl font-bold">
                {t('coInnovationFunds.priorities.details.priorityAreasSubtitle')}
            </h1>
            {data ? (
                <ul className="ml-6 list-disc">
                    {data.priorityAreas.map((x, index) => (
                        <li key={index}>{x}</li>
                    ))}
                </ul>
            ) : null}
        </article>
    );
};
