import { useCallback, useState } from 'react';

import { CellContext, ColumnSort } from '@tanstack/react-table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { anyFilter } from '../../../shared';
import { FundsByPartnerResponse } from '../table/common/domain';
import { useFundsByPartnerQuery } from '../table/common/useFundsPartnerQuery';

import { ReactComponent as ChevronRight } from '@assets/icons/chevronRight.svg';
import { useResizeMediaQuery } from '@features/layout';
import { namespaces } from '@shared/constants';
import { CustomColumn, MetaCellProps, MobileTable, Table, useDownloadContext } from '@shared/ui';
import { useStore } from '@store';

const defaultCellProps: MetaCellProps = { className: '', hasWarningIcon: false };

const indentMeta = {
    getCellProps: (context: CellContext<FundsByPartnerResponse[0], unknown>) =>
        context.row.original.partnerId ? defaultCellProps : { ...defaultCellProps, className: 'pl-2' },
};

const formatData = (showSubPartners: boolean, data?: FundsByPartnerResponse | null, partnerId?: string) => {
    if (!data || (!partnerId && !showSubPartners)) {
        return data;
    }

    const output: FundsByPartnerResponse = [];
    data.forEach(item => {
        if (showSubPartners) {
            output.push({ ...item, subData: [] });
            item.subData?.forEach(subItem => {
                output.push({
                    ...subItem,
                    subRow: true,
                    subData: [],
                });
            });
        } else {
            output.push(item);
            if (item.partnerId === partnerId) {
                item.subData?.forEach(subItem => {
                    output.push({
                        ...subItem,
                        subRow: true,
                        subData: [],
                    });
                });
            }
        }
    });

    return output;
};

type Props = {
    showSubPartners: boolean;
};

const ByPartnerTable = ({ showSubPartners }: Props) => {
    const { isMobile } = useResizeMediaQuery();
    const isDownloading = useDownloadContext();
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const partiallySetFilter = useStore(state => state.coInnovationFunds.partiallySetFilter);
    const idsWithChewron: string[] = [];

    const consumedDescription = t('coInnovationFunds.summary.consumed.description');
    const allocatedDescription = t('coInnovationFunds.summary.allocated.description');
    const providedDescription = t('coInnovationFunds.summary.provided.description');
    const expiredDescription = t('coInnovationFunds.summary.expired.description');
    const availableDescription = t('coInnovationFunds.summary.available.description');

    const [{ id: orderBy, desc }, setSorting] = useState<ColumnSort>({
        id: 'name',
        desc: false,
    });

    const [selectedRowId, setSelectedRowId] = useState<string>();

    const {
        query: { data: summaryData, isFetching },
        filter,
    } = useFundsByPartnerQuery({ id: orderBy, desc: desc });

    summaryData?.forEach(item => {
        if (item.subData?.length && item.subData.length > 0) {
            idsWithChewron.push(item.partnerId);
        }
    });
    const onClickFilter = useCallback(
        (id: string) => {
            const selectedItem = summaryData?.find(x => x.partnerId === id);
            if (selectedItem?.subData?.length && selectedItem.subData.length > 0) {
                partiallySetFilter({ partners: selectedItem.subData.map(x => x.partnerId) });
            } else {
                partiallySetFilter({ partners: [id] });
            }
        },
        [summaryData, partiallySetFilter]
    );

    const columns: CustomColumn<FundsByPartnerResponse>[] = [
        {
            accessorKey: 'partnerId',
            cellType: 'id',
            visible: false,
        },
        {
            accessorKey: 'name',
            header: t('coInnovationFunds.summary.partners.partner'),
            cellType: 'text',
            size: 14,
            sortable: true,
            selectedColumnCallback: onClickFilter,
            meta: indentMeta,
        },
        {
            accessorKey: 'provided',
            header: t('coInnovationFunds.summary.partners.provided'),
            cellType: 'currency',
            size: 13,
            sortable: true,
            tooltip: providedDescription,
        },
        {
            accessorKey: 'allocated',
            header: t('coInnovationFunds.summary.partners.allocated'),
            cellType: 'currency',
            size: 13,
            sortable: true,
            tooltip: allocatedDescription,
        },
        {
            accessorKey: 'consumed',
            header: t('coInnovationFunds.summary.partners.consumed'),
            cellType: 'currency',
            size: 13,
            sortable: true,
            tooltip: consumedDescription,
        },
        {
            accessorKey: 'available',
            header: t('coInnovationFunds.summary.partners.available'),
            cellType: 'currency',
            size: 13,
            sortable: true,
            tooltip: availableDescription,
        },
        {
            accessorKey: 'expired',
            header: t('coInnovationFunds.summary.partners.expired'),
            cellType: 'currency',
            size: 13,
            sortable: true,
            tooltip: expiredDescription,
        },
        {
            accessorKey: 'actions',
            cellType: 'actions',
            header: '',
            size: 4,
            visible: true,
            actions: [
                {
                    icon: (
                        <span className="text-blue-800">
                            <ChevronRight />
                        </span>
                    ),
                    rowAccessorKey: 'partnerId',
                    onClick: id =>
                        setSelectedRowId(previousId => (previousId === id.toString() ? undefined : id.toString())),
                    hide: id => showSubPartners || !idsWithChewron.some(x => x === id),
                    active: id => id === selectedRowId,
                },
            ],
        },
    ];

    const isFiltered = anyFilter(filter);

    const dataWithSubRows = formatData(showSubPartners, summaryData, selectedRowId);
    const isLoading = isFetching && !summaryData;
    return (
        <div
            className={classNames('col-span-2 overflow-auto', '[&_table_tbody_tr_td_button.active]:rotate-90', {
                'text-[12px] [&_table_tbody_tr_td_div]:pr-0 [&_table_thead_tr_th_button]:text-right': isDownloading,
            })}
        >
            {isMobile ? (
                <MobileTable
                    columns={columns}
                    data={dataWithSubRows ?? []}
                    pivotKey="name"
                    isLoading={isLoading}
                    idKey="partnerId"
                    totalItems={summaryData?.length}
                    selectedRowId={selectedRowId}
                    setSelectedRowId={setSelectedRowId}
                ></MobileTable>
            ) : (
                <Table
                    columns={columns}
                    totalItems={summaryData?.length}
                    data={dataWithSubRows ?? []}
                    tableClassNames={'max-h-[670px]'}
                    caption={t('coInnovationFunds.navigation.summary')}
                    sorting={{ id: orderBy, desc }}
                    sortingCallback={setSorting}
                    isLoading={isLoading}
                    isFiltered={isFiltered}
                    isOuterBorderless={!!isDownloading}
                />
            )}
        </div>
    );
};

export { ByPartnerTable };
